import type {
  TUser,
  TUserSession,
  TUserStudent,
} from "@resposta-certa/api-client/types";
import { defineStore } from "pinia";

interface AuthState {
  token: string;
  user: TUser | null;
  student: TUserStudent | null;
  totalQuestionsToday: number;
  studentStreak: {
    streak: number;
    week: {
      dayOfTheWeek: number;
      status: string;
      label: string;
    }[];
  };
  subjects: {
    questions: number;
    score: number;
    id: string;
    smallDescription: string;
    title: string;
  }[];
  subjectsStars: {
    id: string;
    title: string;
    stars: number;
  }[];
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    token: "",
    user: null,
    student: null,
    studentStreak: {
      streak: 0,
      week: [],
    },
    totalQuestionsToday: 0,
    subjects: [],
    subjectsStars: [],
  }),
  actions: {
    login(user: TUser, session: TUserSession, student: TUserStudent | null) {
      const token = useCookie("token", {
        expires: new Date(session.expireAt),
      });

      this.token = session.token;
      this.user = user;
      this.student = student;

      token.value = session.token;
    },
    logout() {
      const token = useCookie("token", {
        expires: new Date(0),
      });

      token.value = "";
      this.token = "";
      this.user = null;
    },
  },
  getters: {
    isPremium(state) {
      if (!state.user?.subscribedUntil) return false;

      const subscriptionEndDate = new Date(state.user.subscribedUntil);
      return subscriptionEndDate > new Date();
    },
    userFirstname(state) {
      return state.user?.fullname.split(" ")[0] as string;
    },
    totalStars(state) {
      return state.subjectsStars.reduce((acc, curr) => acc + curr.stars, 0);
    },
    totalQuestions(state) {
      return state.subjects.reduce((acc, curr) => acc + curr.questions, 0);
    },
  },
});
